import "./fonts.css";
import { LNG_DEFAULT } from "@/utils/constants";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
} from "react";
import ToggleBodyStyle from "@/utils/ToggleBodyStyle";
import { getUserToken } from "@/api/createTokenProvider";
import { useLangStore } from "@/store/lang-store";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const ModalConfirmUserCentered = lazy(() =>
  import("@/components/Modals/ModalConfirmUserCentered")
);
//import ModalConfirmUserCentered from "./components/Modals/ModalConfirmUserCentered";
const Routes = lazy(() => import("@/layouts/Routes"));
//import Routes from "@/layouts/Routes";
const GlobalStyle = lazy(() => import("@/utils/GlobalStyle"));
//import GlobalStyle from "@/utils/GlobalStyle";
const Theme = lazy(() => import("@/utils/Theme"));
//import Theme from "@/utils/Theme";
const ScrollToTop = lazy(() => import("@/utils/ScrollToTop"));
// import ScrollToTop from "@/utils/ScrollToTop";
const CookiesComponent = lazy(() =>
  import("@/components/cookies/CookiesComponent")
);
// import CookiesComponent from "@/components/cookies/CookiesComponent";
const ModalLoginCentered = lazy(() =>
  import("@/components/Modals/ModalLoginCentered")
);
//import ModalLoginCentered from "@/components/Modals/ModalLoginCentered";
const ModalResetCentered = lazy(() =>
  import("@/components/Modals/ModalResetCentered")
);
//import ModalResetCentered from "@/components/Modals/ModalResetCentered";
const AccountCreationPopup = lazy(() =>
  import("@/components/popups/AccountCreationPopup")
);
// import AccountCreationPopup from "@/components/popups/AccountCreationPopup";
//const Cookie5Days = lazy(() => import("./utils/cookies"));
// import Cookie5Days from "./utils/cookies";
const MazoutLoader = lazy(() => import("@/components/_common/MazoutLoader"));

// Create a new context object using the createContext function
// This context will be used to share data between components
export const DataContext = createContext();

// The App component is the root component of our application
const App = () => {
  const [lang, setLang] = useState(null);
  const [properties, setProperties] = useState(null);
  const [routeMap, setRouteMap] = useState(null);
  const { langStore, setLangStore } = useLangStore();
  const [, setUserValue] = useState(getUserToken());
  const [isAccountPopupVisible, setIsAccountPopupVisible] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(false);
  const [displayComfirmUser, setDisplayComfirmUser] = useState(false);
  const [setDisplay5days] = useState(false);
  const [displayReset, setDisplayReset] = useState(false);
  const [displayResetConditions, setDisplayResetConditions] = useState(null);
  const [displayConfConditions, setDisplayConfConditions] = useState(null);
  const [displayLoginConditions, setDisplayLoginConditions] = useState(null);
  const LanguesFR = useMemo(
    () => ["fr-BE", "fr-FR", "fr", "fr-CA", "fr-CH", "fr-LU", "fr-MC"],
    []
  );

  const LanguesNL = useMemo(
    () => ["nl-BE", "nl-NL", "nl", "nl-AW", "nl-BQ", "nl-CW", "nl-SX"],
    []
  );

  const LanguesEN = useMemo(
    () => ["en-US", "en-GB", "en", "en-AU", "en-CA", "en-NZ", "en-IE"],
    []
  );

  const changeAppLanguage = useCallback(() => {
    let folderLang = "";
    if (LanguesFR.includes(langStore)) {
      folderLang = "fr-BE";
    } else if (LanguesNL.includes(langStore)) {
      folderLang = "nl-BE";
    } else if (LanguesEN.includes(langStore)) {
      folderLang = "nl-BE";
    } else {
      folderLang = "nl-BE";
    }
    const textPath = `/static/${folderLang}/text.json`;
    fetch(textPath)
      .then((response) => response.json())
      .then((json) => setLang(json))
      .catch((error) => console.error(error));

    const propsPath = `/static/${folderLang}/properties.json`;
    fetch(propsPath)
      .then((response) => response.json())
      .then((json) => setProperties(json))
      .catch((error) => console.error(error));

    const routeMapPath = `/static/${folderLang}/route.json`;
    fetch(routeMapPath)
      .then((response) => response.json())
      .then((json) => setRouteMap(json))
      .catch((error) => console.error(error));
  }, [LanguesEN, LanguesFR, LanguesNL, langStore]);

  useEffect(() => {
    const urlArray = window.location.pathname.split("/");
    if (urlArray.length > 1 && urlArray[1] === "belgie") {
      setLangStore("nl-BE");
    } else if (urlArray.length > 1 && urlArray[1] === "belgique") {
      setLangStore("fr-BE");
    } else if (localStorage.getItem("USERLANG")) {
      if (localStorage.getItem("USERLANG").substring(0, 2) === "fr") {
        setLangStore("fr-BE");
      } else {
        setLangStore("nl-BE");
      }
    } else if (navigator.language || navigator.userLanguage) {
      let browserLng = (navigator.language || navigator.userLanguage).split(
        "-"
      )[0];
      if (browserLng.substring(0, 2) === "fr") {
        setLangStore("fr-BE");
      } else {
        setLangStore("nl-BE");
      }
    } else {
      setLangStore(LNG_DEFAULT);
    }
  }, []);

  // Call the getCurrentLanguage function when the component mounts
  useEffect(() => {
    // setLangStore(localStorage.getItem('lang') || 'nl-BE');
    // Respond to the `storage` event
    changeAppLanguage();
  }, [changeAppLanguage]);

  const toggleLoginPopup = useCallback(
    (e) => {
      if (e?.detail) setDisplayLoginConditions(e.detail);
      setDisplayLogin((prev) => !prev);
    },
    [setDisplayLogin]
  );

  const toggleResetPopup = useCallback(
    (e) => {
      e?.detail ? setDisplayResetConditions(e.detail) : null;
      setDisplayReset((prev) => !prev);
    },
    [setDisplayReset]
  );

  const toggleconfirmUserForm = useCallback(
    (e) => {
      if (e?.detail) {
        setDisplayConfConditions({
          confKey: e.detail?.key,
          type: e.detail?.type,
        });
      }
      setDisplayComfirmUser((prev) => !prev);
    },
    [setDisplayComfirmUser]
  );

  const closeAllPopup = useCallback(() => {
    setDisplayReset(false);
    setDisplayLogin(false);
    setDisplayComfirmUser(false);
    setIsAccountPopupVisible(false);
  }, [
    setDisplayReset,
    setDisplayLogin,
    setIsAccountPopupVisible,
    setDisplayComfirmUser,
  ]);
  const toggleDisplay5days = useCallback(
    () => setDisplay5days((prev) => !prev),
    [setDisplay5days]
  );
  const handleStorage = useCallback(() => {
    const userToken = getUserToken();
    setUserValue(userToken);
  }, [setUserValue]);
  const toggleAccountPopup = useCallback(
    () => setIsAccountPopupVisible((prev) => !prev),
    [setIsAccountPopupVisible]
  );

  useEffect(() => {
    window.addEventListener("tokenUserChanged", handleStorage);
    return () => window.removeEventListener("tokenUserChanged", handleStorage);
  }, [handleStorage]);

  useEffect(() => {
    window.addEventListener("ToogleAccountPopup", toggleAccountPopup);
    return () =>
      window.removeEventListener("ToogleAccountPopup", toggleAccountPopup);
  }, [toggleAccountPopup]);

  useEffect(() => {
    window.addEventListener("ToggleLoginForm", toggleLoginPopup);
    return () =>
      window.removeEventListener("ToggleLoginForm", toggleLoginPopup);
  }, [toggleLoginPopup]);

  useEffect(() => {
    window.addEventListener("toggleconfirmUserForm", toggleconfirmUserForm);
    return () =>
      window.removeEventListener(
        "toggleconfirmUserForm",
        toggleconfirmUserForm
      );
  }, [toggleconfirmUserForm]);

  useEffect(() => {
    window.addEventListener("CloseAnyForm", closeAllPopup);
    return () => window.removeEventListener("CloseAnyForm", closeAllPopup);
  }, [closeAllPopup]);

  useEffect(() => {
    window.addEventListener("ToggleResetForm", toggleResetPopup);
    return () =>
      window.removeEventListener("ToggleResetForm", toggleResetPopup);
  }, [toggleResetPopup]);

  useEffect(() => {
    window.addEventListener("ToggleDisplay5days", toggleDisplay5days);
    return () =>
      window.removeEventListener("ToggleDisplay5days", toggleDisplay5days);
  }, [toggleDisplay5days]);

  useEffect(() => {
    if (
      displayReset ||
      displayLogin ||
      isAccountPopupVisible ||
      displayComfirmUser
    ) {
      ToggleBodyStyle();
    } else {
      ToggleBodyStyle(true);
    }
  }, [displayReset, displayLogin, isAccountPopupVisible, displayComfirmUser]);

  return (
    <Theme>
      <Suspense fallback={<MazoutLoader $logo />}>
        <BrowserRouter>
          <ScrollToTop />
          <GlobalStyle />
          <DataContext.Provider value={{ lang, properties, routeMap }}>
            <CookiesProvider>
              <HelmetProvider>
                <CookiesComponent />
                {/* <Cookie5Days /> */}
                {isAccountPopupVisible && (
                  <AccountCreationPopup
                    setIsAccountPopupVisible={setIsAccountPopupVisible}
                  />
                )}
                {displayComfirmUser && (
                  <ModalConfirmUserCentered {...displayConfConditions} />
                )}
                {displayLogin && (
                  <ModalLoginCentered {...displayLoginConditions} />
                )}
                {displayReset && (
                  <ModalResetCentered {...displayResetConditions} />
                )}

                {<Routes />}
              </HelmetProvider>
            </CookiesProvider>
          </DataContext.Provider>
        </BrowserRouter>
      </Suspense>
    </Theme>
  );
};

export default App;
