import { LNG_DEFAULT } from "@/utils/constants";
// zustand/store.js

import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useLangStore = create(
  persist(
    (set) => ({
      langStore: LNG_DEFAULT,
      setLangStore: (langStore) => set({ langStore: langStore }),
    }),
    {
      name: "lng-storage",
    }
  )
);
