const ToggleBodyStyle = (forceClose = false) => {
  if (document) {
    if (document.body.style.overflow === "hidden" || forceClose === true) {
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.documentElement.style.overflow = "";
      document.documentElement.style.height = "";
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
    }
  }
};

export default ToggleBodyStyle;
