const UpdateUser = ({ userToken, userStore, setUserStore }) => {
  let user = userStore ?? {};

  if (userToken?.firstName) user.firstName = userToken?.firstName;
  if (userToken?.lastName) user.lastName = userToken?.lastName;
  if (userToken?.email) user.email = userToken?.email;
  if (userToken?.phone) user.phone = userToken?.phone;
  if (userToken?.companyName) user.companyName = userToken?.companyName;
  if (userToken?.vat) user.vat = userToken?.vat;
  if (userToken?.groups?.buyerUser?.superBuyerUser)
    user.superBuyerUser = userToken?.groups?.buyerUser?.superBuyerUser;
  if (userToken?.blockRequestBigBuyer)
    user.blockRequestBigBuyer = userToken?.blockRequestBigBuyer;

  //Address in the order can differt from the user address
  //Set from last order (Same Session)
  if (userToken?.order?.firstName)
    user.order.firstName = userToken?.order?.firstName;
  if (userToken?.order?.lastName)
    user.order.lastName = userToken?.order?.lastName;
  if (userToken?.order?.phone) user.order.phone = userToken?.order?.phone;
  if (userToken?.order?.address1)
    user.order.address1 = userToken?.order?.address1;
  if (userToken?.order?.address2)
    user.order.address2 = userToken?.order?.address2;
  if (userToken?.order?.companyName)
    user.order.companyName = userToken?.order?.companyName;
  if (userToken?.order?.vat) user.order.vat = userToken?.order?.vat;
  if (userToken?.order?.superBuyerUser)
    user.order.superBuyerUser = userToken?.order?.superBuyerUser;
  if (userToken?.order?.blockRequestBigBuyer)
    user.order.blockRequestBigBuyer = userToken?.order?.blockRequestBigBuyer;

  //Set from loaded user (IF EMPTY)
  if (!user.order) user.order = {};
  if (user?.order?.firstName && userToken?.firstName)
    user.order.firstName = userToken?.firstName;
  if (user?.order?.lastName && userToken?.lastName)
    user.order.lastName = userToken?.lastName;
  if (user?.order?.phone && userToken?.phone)
    user.order.phone = userToken?.phone;
  if (user?.order?.companyName && userToken?.companyName)
    user.order.companyName = userToken?.companyName;
  if (user?.order?.vat && userToken?.vat) user.order.vat = userToken?.vat;
  if (user?.order?.superBuyerUser && userToken?.superBuyerUser)
    user.order.superBuyerUser = userToken?.superBuyerUser;
  if (user?.order?.blockRequestBigBuyer && userToken?.blockRequestBigBuyer)
    user.order.blockRequestBigBuyer = userToken?.blockRequestBigBuyer;

  if (!user.order.address1 && userToken?.address1)
    user.order.address1 = userToken?.address1;
  if (!user.order.address2 && userToken?.address2)
    user.order.address2 = userToken?.address2;

  setUserStore(user);
  window.dispatchEvent(new CustomEvent("userStoreChanged"));
};

export default UpdateUser;
