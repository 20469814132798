/**
 * This function creates a token provider that manages user tokens.
 *
 * @returns {Object} An object with the following properties:
 *  - getToken: A function that returns the current access token.
 *  - getUserToken: A function that returns the current user with access token.
 *  - isLoggedIn: A function that returns whether the user is logged in.
 *  - setUserToken: A function that sets the user and the access token.
 *  - subscribe: A function that subscribes an observer.
 *  - unsubscribe: A function that unsubscribes an observer.
 */

/**
 * Get the expiration date of the access token.
 *
 * @param {string} jwtToken - The JWT token.
 * @returns {number|null} The expiration date in milliseconds.
//  */
// const getExpirationDate = (jwtToken) => {
//   if (!jwtToken) {
//     return null;
//   }

//   const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

//   return (jwt && jwt.exp && jwt.exp * 1000) || null;
// };

/**
 * Check if the token is expired.
 *
 * @param {number} exp - The expiration date in milliseconds.
 * @returns {boolean} Whether the token is expired.
 */
// const isExpired = (exp) => {
//   if (!exp) {
//     return false;
//   }

//   return Date.now() > exp;
// };

/**
 * Get the access token.
 *
 * @returns {Promise<string|null>} The access token.
 */
const getToken = () => {
  let _user = JSON.parse(localStorage.getItem("REACT_USER_AUTH")) || null;
  if (!_user) {
    return null;
  }

  return _user.token; //&& _token.accessToken;
};

/**
 * Get the user.
 *
 * @returns {Promise<string|null>} The access token.
 */
const getUserToken = () => {
  let _user = JSON.parse(localStorage.getItem("REACT_USER_AUTH")) || null;
  if (!_user) {
    return null;
  }

  return _user; //&& _token.accessToken;
};

/**
 * Check if the user is logged in.
 *
 * @returns {boolean} Whether the user is logged in.
 */
const isLoggedIn = () => {
  let _user = JSON.parse(localStorage.getItem("REACT_USER_AUTH")) || null;
  return !!_user;
};

// The observers that will be notified when the token changes.
let observers = [];

/**
 * Subscribe an observer.
 *
 * @param {Function} observer - The observer function.
 */
const subscribe = (observer) => {
  observers.push(observer);
};

/**
 * Unsubscribe an observer.
 *
 * @param {Function} observer - The observer function.
 */
const unsubscribe = (observer) => {
  observers = observers.filter((_observer) => _observer !== observer);
};

/**
 * Notify the observers.
 */
const notify = () => {
  const isLogged = isLoggedIn();
  observers.forEach((observer) => observer(isLogged));
};
/**
 * Set the user.
 *
 * @param {Object|null} user - The user object or null.
 */
import { useUserStore } from "@/store/user-store";
import UpdateUser from "@/api/UpdateUser.js";
const setUserToken = (user) => {
  if (user) {
    if (user.token) {
      localStorage.setItem("REACT_USER_AUTH", JSON.stringify(user));
    }
    UpdateUser({
      userToken: user,
      userStore: useUserStore.getState().userStore,
      setUserStore: useUserStore.getState().setUserStore,
    });
  } else {
    localStorage.removeItem("REACT_USER_AUTH");
  }

  window.dispatchEvent(
    new CustomEvent("tokenUserChanged", {
      detail: user,
    })
  );
};

export {
  getToken,
  getUserToken,
  isLoggedIn,
  setUserToken,
  subscribe,
  unsubscribe,
};
